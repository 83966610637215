/* External Packages */
import 'bootstrap';
import 'popper.js';
import './scripts/jqueryLib';

/* Internal Files */
import general from './scripts/general.js';
import navigation from './scripts/navigation.js';
import blocks from './blocks/blocks.js';
import handleDropdownToggle from './blocks/state-dropdown';
import dynamicText from './components/text.js';
import shoppingCart from './scripts/shoppingCart';
import domainFooter from './scripts/domainFooter';
import handleDomainSearchForm from './blocks/domain-search.js';
import getStateImage from './blocks/state-selector';
import handleStateNav from './blocks/biz-reporting-nav';
import handleBackToTop from './blocks/back-top-button';
import handleReportingIndexSort from './blocks/reporting-index-table';

/* loader.js is bundled separately via Webpack. Do not import it here! */
/* lighthouse.js is bundled separately via Webpack. Do not import it here! */
document.addEventListener('DOMContentLoaded', function () {
    general();
    navigation();
    blocks();
    handleDropdownToggle();
    dynamicText();
    shoppingCart();
    domainFooter();
    handleDomainSearchForm();
    getStateImage();
    handleStateNav();
    handleBackToTop();
    handleReportingIndexSort();
});